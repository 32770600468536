.image-marquee {
  width: calc(100% * 2); /* Удвоенная ширина для учета дублированных изображений */
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  animation: marquee 15s linear infinite;
}

@keyframes marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}