html, body, #root {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  /* min-height: 400px; */
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: '#fff';
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiMobileStepper-dots .MuiMobileStepper-dot {
  width: 5vw;
  border-radius: 10vw;
  background: rgba(255, 255, 255, 0.20);
  height: 0.8vh;
}
.MuiMobileStepper-dots .MuiMobileStepper-dot.MuiMobileStepper-dotActive {
  width: 18vw;
  border-radius: 10vw;
  background-color: white;
  height: 0.8vh;
}

.profileStepper .MuiMobileStepper-dots .MuiMobileStepper-dot {
  width: 4vw;
  border-radius: 10vw;
  background: rgba(255, 255, 255, 0.20);
  height: 0.5vh;
}
.profileStepper .MuiMobileStepper-dots .MuiMobileStepper-dot.MuiMobileStepper-dotActive {
  width: 4vw;
  border-radius: 10vw;
  background-color: white;
  height: 0.5vh;
}