.App {
  text-align: center;
  /* min-height: 85vh; */
  min-height: 100%;
  height: 100%;
}

.cs-typing-indicator {
  display: flex;
  justify-content: center;
}


@media (prefers-reduced-motion: no-preference) {
  .spinLeft {
    animation: spinLeft infinite 1s linear;
  }
}

@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.image-marquee {
  width: calc(100% * 2); /* Удвоенная ширина для учета дублированных изображений */
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  animation: marquee 15s linear infinite;
}

@keyframes marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}